import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addToCart } from "../features/cartSlice";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";


const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
};

const Home = () => {
  const { items: data, status } = useSelector((state) => state.products);
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
    navigate("/cart");
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    customPaging: (i) => <div>{i + 1}</div>
  };


  return (
    <div className="home-container">
      
      {status === "success" ? (
        <>
          <div className="slider-container">
            <Slider {...settings}>
              {data &&
                data?.map((product) => (
                  <div key={product._id} className="product-overlay">
                    <Link to={`/product/${product._id}`}>
                      <img
                        src={product.image.url}
                        alt={product.name}
                        className="product-image"
                      />
                    </Link>
                    <div className="product-details">
                      <h3>{product.name}</h3>
                      <div className="details">
                        <span>{product.desc}</span>
                        <span className="price">R{product.price}</span>
                      </div>
                      <button onClick={() => handleAddToCart(product)}>
                        {" "}
                        Add to Cart{" "}
                      </button>
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
        </>
      ) : status === "pending" ? (
        <p> Loading ... </p>
      ) : (
        <p>Unexpected error occurred ... </p>
      )}

    </div>
  );
};

export default Home;
