import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addToCart } from "../features/cartSlice";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
};

const ForshoeCollection = () => {
  const { items: data, status } = useSelector((state) => state.shoeCollections);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAddToCart = (shoeCollection) => {
    dispatch(addToCart(shoeCollection));
    navigate("/cart");
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    customPaging: (i) => <div>{i + 2}</div>
  };
 
  return (
    <div className="Home-container">
      {status === "success" ? (
        <>
          <h2>We like Nice Shoes! 18 April 2024</h2>
          <div className="products">
            {data &&
              data.map((shoeCollection) => (
                <div key={shoeCollection._id} className="product-overlay">
                  <h3>{shoeCollection.name}</h3>
                  <img 
                  src={shoeCollection.image.url} 
                  alt={shoeCollection.name} 
                  className="product-image"
                  />
                  <Link to={`/shoeCollection/${shoeCollection._id}`}>
                    View Details
                  </Link>
                  <div className="product-details">
                    <span>{shoeCollection.desc}</span>
                    <span>{shoeCollection.content}</span>
                  </div>
                  <button onClick={() => handleAddToCart(shoeCollection)}>
                    Add To Cart
                  </button>
                </div>
              ))}
          </div>
        </>
      ) : status === "pending" ? (
        <p>Loading...</p>
      ) : (
        <p>Unexpected error occurred...</p>
      )}
    </div>
  );
};

export default ForshoeCollection;
