import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";

import productsReducer, { productsFetch } from "./features/productsSlice";
import { productsApi } from './features/productsApi';
import { jewelleryCollectionsApi } from './features/jewelleryCollectionsApi';
import { thriftCollectionsApi } from './features/thriftCollectionsApi';
import { shoeCollectionsApi } from './features/shoeCollectionsApi';
import { postCollectionsApi } from './features/postCollectionsApi';
import jewelleryCollectionsReducer, { jewelleryCollectionsFetch } from "./features/jewelleryCollectionsSlice";
import thriftCollectionsReducer, { thriftCollectionsFetch } from "./features/thriftCollectionsSlice";
import shoeCollectionsReducer, { shoeCollectionsFetch } from "./features/shoeCollectionsSlice";
import postCollectionsReducer, { postCollectionsFetch } from "./features/postCollectionsSlice";
import cartReducer, {getTotals} from "./features/cartSlice";
import authReducer, { loadUser } from "./features/authSlice";
import ordersSlice from "./features/ordersSlice";
import usersSlice from "./features/usersSlice";

const store = configureStore({
  reducer: {
    products: productsReducer,
    jewelleryCollections: jewelleryCollectionsReducer,
    thriftCollections: thriftCollectionsReducer,
    shoeCollections: shoeCollectionsReducer,
    postCollections: postCollectionsReducer,
    orders: ordersSlice,
    users: usersSlice,
    cart: cartReducer,
    auth: authReducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [jewelleryCollectionsApi.reducerPath]: jewelleryCollectionsApi.reducer,
    [thriftCollectionsApi.reducerPath]: thriftCollectionsApi.reducer,
    [shoeCollectionsApi.reducerPath]: shoeCollectionsApi.reducer,
    [postCollectionsApi.reducerPath]: postCollectionsApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(productsApi.middleware, jewelleryCollectionsApi.middleware, thriftCollectionsApi.middleware, shoeCollectionsApi.middleware, postCollectionsApi.middleware);
  },
});

store.dispatch(productsFetch());
store.dispatch(jewelleryCollectionsFetch());
store.dispatch(thriftCollectionsFetch());
store.dispatch(shoeCollectionsFetch());
store.dispatch(postCollectionsFetch());
store.dispatch(getTotals());
store.dispatch(loadUser(null));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store = {store}>
      <App />
      </Provider>
  </React.StrictMode>
);

