import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addToCart } from "../features/cartSlice";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ForthriftCollection = () => {
  const { items: data, status } = useSelector((state) => state.thriftCollections);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleAddToCart = (thriftCollection) => {
    dispatch(addToCart(thriftCollection));
    navigate("/cart");
  };

 
  return (
    <div className="Home-container">
      {status === "success" ? (
        <>
          <div className="slider-container">
            {data &&
              data.map((thriftCollection) => (
                <div key={thriftCollection._id} className="product-overlay">
                  <h3>{thriftCollection.name}</h3>
                  <img 
                  src={thriftCollection.image.url} 
                  alt={thriftCollection.name} 
                  className="product-image"
                  />
                  <Link to={`/thriftCollection/${thriftCollection._id}`}>
                    View Details
                  </Link>
                  <div className="product-details">
                    <span>{thriftCollection.desc}</span>
                    <span>{thriftCollection.content}</span>
                  </div>
                  <button onClick={() => handleAddToCart(thriftCollection)}>
                    Add To Cart
                  </button>
                </div>

              ))}
          </div>
        </>
      ) : status === "pending" ? (
        <p> Loading ... </p>
      ) : (
        <p>Unexpected error occured ... </p>
      )}
    </div>
  );
}

export default ForthriftCollection;
