import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import CheckoutSuccess from "./components/CheckoutSuccess";

import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import Cart from "./components/Cart";
import Home from "./components/Home";
import NotFound from "./components/NotFound";
import ForjewelleryCollection from "./components/ForjewelleryCollection";
import ForthriftCollection from "./components/ForthriftCollection";
import ForshoeCollection from "./components/ForshoeCollection";
import ForPost from "./components/ForPost";
import About from "./components/About";
import Contact from "./components/Contact";
import Dashboard from "./components/admin/Dashboard";
import shipping from "./components/shipping";
import Terms from "./components/Terms";
import Returns from "./components/Returns";
import Orders from "./components/admin/Orders";
import UserProfile from "./components/Details/UserProfile";
import Order from "./components/Details/Order";
import Product from "./components/Details/Product";
import PostCollection from "./components/Details/PostCollection";
import JewelleryCollection from "./components/Details/JewelleryCollection";
import ThriftCollection from "./components/Details/ThriftCollection";
import ShoeCollection from "./components/Details/ShoeCollection";
import Socials from "./components/admin/Socials";
import Calendar from "./components/admin/Calendar";
import Products from "./components/admin/Products";
import NewJewelleryCollections from "./components/admin/NewJewelleryCollections";
import NewThriftCollections from "./components/admin/NewThriftCollections";
import NewShoeCollections from "./components/admin/NewShoeCollections";
import NewPostCollections from "./components/admin/NewPostCollections.jsx";
import Summary from "./components/admin/Summary";
import CreateProduct from "./components/admin/CreateProduct";
import CreateJewelleryCollection from "./components/admin/CreateJewelleryCollection.jsx";
import CreateThriftCollection from "./components/admin/CreateThriftCollection.jsx";
import CreatePostCollection from "./components/admin/CreatePostCollection.jsx";
import CreateShoeCollection from "./components/admin/CreateShoeCollection.jsx";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadUser } from "./features/authSlice";
import ProductsList from "./components/admin/list/ProductsList";
import Users from "./components/admin/Users";
import JewelleryCollectionsList from "./components/admin/list/JewelleryCollectionsList";
import PostCollectionsList from "./components/admin/list/PostCollectionsList.jsx";
import ThriftCollectionsList from "./components/admin/list/ThriftCollectionsList";
import ShoeCollectionsList from "./components/admin/list/ShoeCollectionsList";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadUser(null));
  }, [dispatch]);

  const bgStyle = {
    backgroundImage: `
    url(${process.env.PUBLIC_URL + '/images/background0.jpg'}),
    url(${process.env.PUBLIC_URL + '/images/background1.jpg'})
`,
backgroundSize: '100%', // Cover the entire container
backgroundPosition: 'repeat', // Center the background images
height: '100%', // Full viewport height
width: '100%', // Full viewport width
};

  return (
    <div className="App">
      <Router>
        <ToastContainer />
        <NavBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/checkout-success" element={<CheckoutSuccess />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/street-wear" element={<ForthriftCollection />} />
            <Route path="/jewellery-wear" element={<ForjewelleryCollection />} />
            <Route path="/shoe-wear" element={<ForshoeCollection />} />
            <Route path="/medium-posts" element={<ForPost />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/shipping" element={<shipping />} />
            <Route path="/Returns" element={<Returns />} />
            <Route path="Terms" element={<Returns />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/product/:id" element={<Product />} />
            <Route path="/postCollection/:id" element={<PostCollection />} />
            <Route path="/jewelleryCollection/:id" element={<JewelleryCollection />} />
            <Route path="/thriftCollection/:id" element={<ThriftCollection />} />
            <Route path="/shoeCollection/:id" element={<ShoeCollection />} />
            <Route path="/order/:id" element={<Order />} />
            <Route path="/user/:id" element={<UserProfile />} />
            <Route path="/admin" element={<Dashboard />}>
              <Route path="products" element={<Products />}>
                <Route index element={<ProductsList />} />
                <Route path="create-product" element={<CreateProduct />} />
              </Route>

              <Route path="newjewelleryCollections" element={<NewJewelleryCollections />}>
                <Route index element={<JewelleryCollectionsList />} />
                <Route path="create-jewelleryCollection" element={<CreateJewelleryCollection />} />
              </Route>

              
              <Route path="newthriftCollections" element={<NewThriftCollections />}>
                <Route index element={<ThriftCollectionsList />} />
                <Route path="create-thriftCollection" element={<CreateThriftCollection />} />
              </Route>

              <Route path="newshoeCollections" element={<NewShoeCollections />}>
                <Route index element={<ShoeCollectionsList />} />
                <Route path="create-shoeCollection" element={<CreateShoeCollection />} />
              </Route>

              <Route path="newpostCollections" element={<NewPostCollections />}>
                <Route index element={<PostCollectionsList />} />
                <Route path="create-postCollection" element={<CreatePostCollection />} />
              </Route>

          
              <Route path="summary" element={<Summary />} />
              <Route path="users" element={<Users />} />
              <Route path="orders" element={<Orders />} />
              <Route path="socials" element={<Socials />} />
              <Route path="calendar" element={<Calendar />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
      </Router>
    </div>
  );
}

export default App;
